import styled from '@emotion/styled';
import { colors } from '../../styles/variables';

export const HeaderDivider = styled.div`
    width: 5rem;
    background-color: ${colors.primary.darkest};
    height: 2px;
    margin-bottom: 3rem;
    animation: widthAnimation 250ms;
    @keyframes widthAnimation {
        from {
            width: 2rem;
        }
        to {
            width: 5rem;
        }
    }
`;
