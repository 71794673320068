import styled from '@emotion/styled';
import _ from 'lodash';
import { Component, createRef } from 'react';
import bedknuf from '../img/polaroids/bedknuf.jpeg';
import cutemorning from '../img/polaroids/cutemorning.jpeg';
import doggo1 from '../img/polaroids/doggo1.jpeg';
import jacuzzi from '../img/polaroids/jacuzzi.jpeg';
import knufbank from '../img/polaroids/knufbank.jpeg';
import menorca from '../img/polaroids/menorca.jpeg';
import poesmaf from '../img/polaroids/poesmaf.jpeg';
import poesmaf1 from '../img/polaroids/poesmaf1.jpeg';
import poesmaf2 from '../img/polaroids/poesmaf2.jpeg';
import poestoe from '../img/polaroids/poestoe.jpeg';
import surifes from '../img/polaroids/surifes.jpeg';
import taart from '../img/polaroids/taart.jpeg';
import trol from '../img/polaroids/trol.jpeg';
import vlieg from '../img/polaroids/vlieg.jpeg';
import wood_bg from '../img/wood_bg.jpg';
import { breakpoints } from '../styles/variables';
import { HeaderDivider } from './atoms/HeaderDivider';
import HearthShape from './HearthShape';
import Polaroid from './molecules/Polaroid';

class LuvContent extends Component {
    gal1: any = createRef();
    gal2: any = createRef();
    gal3: any = createRef();

    scrollToMyRef = (ref: any) => window.scrollTo({ left: 0, top: ref.current.offsetTop - 50, behavior: 'smooth' });
    render() {
        return (
            <Wrapper>
                <Block>
                    <h1>In ander nieuws</h1>
                    <HeaderDivider />
                    <p>
                        Dit is natuurlijk een kleinigheidje maar wel met een persoonlijk tintje. Zoals jij altijd heel mooi een boekje
                        maakt. Iedere polaroid heeft ook een bijtekstje als je erop klikt.
                    </p>
                    - scroll of klik maar maar
                    <HearthShape withGuide={true} direction={'down'} onClick={() => this.scrollToMyRef(this.gal1)} />
                </Block>
                <Gallery ref={this.gal1}>
                    <Polaroid url={bedknuf}>Veel knuffels in bed op zondagen met degene die super veel van je houden.</Polaroid>
                    <Polaroid url={knufbank}>Knuffels op de bank kan ook uiteraard.</Polaroid>
                    <Polaroid url={cutemorning}>Volgens mij heb ik nog nooit zo'n blij ei gezien in de morning.</Polaroid>
                    <Polaroid url={poesmaf}>Dat poes vreemd is, is een understatement vind je niet?</Polaroid>
                    <Polaroid url={surifes}>Af en toe ruzie'tjes 😜, maar dat is al weer een tijdje geleden. Wij </Polaroid>
                </Gallery>
                <Block>
                    <P>
                        Ik heb ook nog een beetje zitten te denken om een leuk cadeautje voor je te verzinnen. Ik luister eigenlijk nooit
                        naar je, maar zo af en toe glipt er toch wel iets in. Zeker als je het een aantal keer herhaalt 😘{' '}
                    </P>
                    <HearthShape withGuide={false} direction={'down'} onClick={() => this.scrollToMyRef(this.gal2)} />
                </Block>
                <Gallery ref={this.gal2}>
                    <Polaroid url={taart}>Geen zorgen, ik zal je geen taart meer geven op je verjaardag. 😜</Polaroid>
                    <Polaroid url={poesmaf2}>Poes spoort niet</Polaroid>

                    <Polaroid url={trol}>
                        Op dat we elkaar nog maar veel mogen trollen. Toch één van mijn favoriete bezigheden. Jij begint er echter ook wat
                        van te kunnen.
                    </Polaroid>
                    <Polaroid url={vlieg}>Laten we als ik ga werken nog maar lekker veel reisjes gaan maken schatje 🛫.</Polaroid>
                    <Polaroid url={poestoe}>Een professionele poestoe uitknijper als vriendin is wel chill eigenlijk</Polaroid>
                </Gallery>

                <Block>
                    <P>Valt je iets op aan de polaroids eigenlijk?</P>

                    <HearthShape withGuide={false} direction={'down'} onClick={() => this.scrollToMyRef(this.gal3)} />
                </Block>
                <Gallery ref={this.gal3}>
                    <Polaroid url={poesmaf1}>Maf maar wel zóóó schattig af en toe. Part of the family.</Polaroid>
                    <Polaroid url={jacuzzi}>Send nudes?</Polaroid>
                    <Polaroid url={doggo1}>Toch ook een liefde voor doggo's. Het liefst klein.</Polaroid>
                    <Polaroid url={menorca}>Chillen met onze billen</Polaroid>
                </Gallery>
                <LoveYou>Op nog vele jaartjes, allerliefste toetselien ♥️</LoveYou>
            </Wrapper>
        );
    }
}

export default LuvContent;

export const LoveYou = styled.h1`
    font-weight: bold;
    @media (min-width: ${breakpoints.md}px) {
        font-size: 5em;
        padding: 10rem;
    }
    padding: 10rem 4rem;
    margin: 0;
    align-self: center;
    color: white;
    text-shadow: 2px 2px #000;
`;
const Block = styled.div`
    :nth-of-type(1) {
        height: 100vh;
        @media (min-width: ${breakpoints.md}px) {
            height: unset;
        }
    }
    /* height: 30rem; */
    align-self: center;
    width: 100%;
    @media (min-width: ${breakpoints.md}px) {
        margin: 10rem;
        width: 30rem;
    }
    background: lightyellow;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 22px ${_.random(0, 100, true)}px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
`;
const Gallery = styled.div`
    margin: 3rem 0;
    width: 100%;
    display: grid;
    @media (min-width: ${breakpoints.md}px) {
        align-self: center;
        width: 50rem;

        grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
    }
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));

    row-gap: 1rem;
`;
const Wrapper = styled.div`
    background: url(${wood_bg});
    background-size: contain;

    background-origin: center;
    @media (min-width: ${breakpoints.md}px) {
        background-size: cover;
    }
    display: flex;
    flex-direction: column;
    margin: -1.5rem;
    padding-bottom: 10rem;
`;

const P = styled.p`
    @media (min-width: ${breakpoints.md}px) {
        padding-top: 10rem;
    }
    padding-top: 3rem;
`;
