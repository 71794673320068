import styled from '@emotion/styled';
import _ from 'lodash';
import { Fragment, PureComponent } from 'react';
import posed from 'react-pose';
import Modal from 'react-responsive-modal';
import { breakpoints } from '../../styles/variables';

interface Props {
    url?: string;
}
class Polaroid extends PureComponent<Props> {
    state = { zoom: false, rotation: 0 };
    componentWillMount = () => {
        this.setState({ rotation: _.random(-30, 30, true) });
    };
    zoomed = (val: boolean) => {
        this.setState({ zoom: val });
    };

    render() {
        const { zoom, rotation } = this.state;
        const { zoomed } = this;
        return (
            <Fragment>
                <Container onClick={() => zoomed(true)} rotation={rotation}>
                    <Content>
                        <Photo url={this.props.url} />
                    </Content>
                </Container>

                <Modal animationDuration={0.5} open={zoom} onClose={() => zoomed(false)}>
                    <SelectedPolaroid url={this.props.url}>
                        <SelectedPhoto src={this.props.url} />
                        <Description>{this.props.children}</Description>
                    </SelectedPolaroid>
                </Modal>
            </Fragment>
        );
    }
}

export default Polaroid;

const Description = styled.div`
    /* background: red; */
    font-family: 'Indie Flower', cursive;
    width: 15rem;
`;
const SelectedPhoto = styled.img<{ url?: string }>`
    max-height: 75vh;
    margin: 2rem 0 1rem;
`;
const SelectedPolaroid = styled.div<{ url?: string }>`
    /* background: url(${({ url }) => (url ? url : 'https://source.unsplash.com/380x340/?love')}); */
    /* opacity: 0.1; */
    animation: fadein 0.5s;
    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const Container = styled.div<{ rotation: number }>`
    position: relative;
    transform: rotate(${({ rotation }) => rotation}deg);
    width: 7rem;
    height: 7rem;
    @media (min-width: ${breakpoints.md}px) {
        width: 13rem;
        height: 13rem;
    }
`;

const Box = posed.div({
    pressable: true,
    init: { scale: 1 },
    press: { scale: 0.8 }
});

const Content = styled(Box)`
    position: absolute;
    @media (min-width: ${breakpoints.md}px) {
        width: 20rem;
        height: 22rem;
    }
    width: 12rem;
    height: 12rem;
    background: white;
    position: relative;
    box-shadow: 0 1px ${_.random(0, 40, true)}px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    border: 1px lightgrey solid;
`;

const Photo = styled.div<{ url?: string }>`
    border: 1px lightgrey solid;
    @media (min-width: ${breakpoints.md}px) {
        width: 17rem;
        height: 15rem;
    }
    height: 9rem;
    width: 10rem;
    background: url(${({ url }) => (url ? url : 'https://source.unsplash.com/380x340/?love')});
    background-size: cover;
    background-position: center;
    position: relative;
`;
